<template>
    <header>
        <div class="BGHeader">
            <v-row class="header">
                <v-col class="d-none d-md-flex">
                    <router-link to="/"><img class="logo" ref="logo" src="../../public/ressources/images/global/logoMini.svg" title="logo 25iem anniverssaire de wx3data" alt="logo 25iem anniverssaire de wx3data" /></router-link>
                </v-col>
                <v-col class="d-flex align-center">
                    <a  class="d-flex d-md-none phoneMobile" href="tel:8198187625">(819) 818-7625</a>
                    <ul class="menu">
                        <li class="d-none d-md-flex"><router-link to="/" v-html="$t('menu.Home')"></router-link></li>
                        <li class="d-flex"><router-link to="/#services" v-html="$t('menu.Services')"></router-link></li>
                        <li class="d-flex"><router-link to="/#realization" v-html="$t('menu.Realization')"></router-link></li>
                        <li class="d-flex"><router-link to="/#contact" v-html="$t('menu.Contact')"></router-link></li>
                    </ul>
                </v-col>
                <v-col class="d-none d-md-flex  justify-end">
                    <a class="phone" href="tel:8198187625">(819) 818-7625</a>
                    <a class="buttonHeader positionHeaderBtn" href="https://abstracxion.com/#/" target="_blank"><v-row style="position: relative;" ><v-col class=""><p class=" d-flex align-center justifer-center text">Abstracxion</p></v-col></v-row></a>
                </v-col>
            </v-row>
        </div>
    </header>
</template>

<style scoped>
    .BGHeader { position: fixed; width: 100%; z-index: 10000; }
    .header { height: 100px; padding: 10px; background: url('../../public/ressources/images/global/square.svg'), rgba(var(--tierce), 0.9); background-position: center; background-repeat: no-repeat; background-size: cover; box-shadow: 0px 5px 50px rgb(var(--secondary)); }
    .v-row { height: 100px; }
    .v-col { height: 100px; }
    .logoFacebook { height: 30px; width: 30px; }
    .logoFacebook:hover { content: url('../../public/ressources/images/global/facebookOrange.svg'); cursor: pointer; }
    .positionHeaderBtn { position: absolute; bottom: -10%;}

    .logo { height: 300%; width: auto; position: absolute; top: 150%; left: 2%; transition: all 1s ease-out; }
    .logo.active { height: 175%; top: 0; left: 0; }
    .menu:hover { color: rgb(var(--secondary)); }
    .menu { gap: 20px; text-transform: uppercase; font-family: Comorant; list-style: none; padding: 0px 20px; margin: 0; display: flex; justify-content: center; }
    .menu a { text-decoration: none; color: rgb(var(--secondary)); font-size: 1.1rem; padding: 5px 0; position: relative; }
    .menu li { position: relative; }
    .menu a:hover { color: rgb(var(--primary)); }
    .menu a::after { content: ''; position: absolute; left: 0%; bottom: 0; height: 1px; width: 0; background-color: rgb(var(--secondary)); transition: width 0.4s ease, left 0.4s ease; }
    .menu a:hover::after { width: 100%; left: 0; }
    .mobile-menu-icon { transition: all 5s ease; }

    .phoneMobile { position: absolute; right: 10px; top:10px; color: rgb(var(--primary)); z-index: 100;}
    .phoneMobile:hover { color: rgb(var(--secondary)); }
    @media handheld, only screen and (max-width: 600px) {

    }
    @media handheld, only screen and (max-width: 960px) {
        .logo { height: 100%; width: auto; position: relative; top: 0%; left: 0%; transition: all 1s ease-out; }
        .logo.active { height: 100%; top: 0; left: 0; }
        .menu {  gap: 10px; padding: 0px 10px;}
        .menu a { font-size: 1rem; }
    }
    @media handheld, only screen and (max-width: 1279px) {

    }
    @media handheld, only screen and (max-width: 1378px) {}
</style>

<script>
import { onMounted, onUnmounted, ref } from 'vue';
import translations from '../../public/ressources/i18n/global.json';

export default {
    name: 'Home',
    setup() {
        const logo = ref(null);
        const triggerPosition = 100; // Par exemple, 300px du haut de la page
        const onScroll = () => {
            if (window.scrollY > triggerPosition && logo.value) {
                logo.value.classList.add('active');
            } else if (window.scrollY <= triggerPosition && logo.value) {
                logo.value.classList.remove('active');
            }
        };
        onMounted(() => { window.addEventListener('scroll', onScroll); });
        onUnmounted(() => { window.removeEventListener('scroll', onScroll); });

        return {
            logo,
        };
    },
    mounted() {
        this.$i18n.mergeLocaleMessage('fr', translations.fr);
        //this.$i18n.mergeLocaleMessage('en', translations.en);
    },
};
</script>
