<template>
  <div class="home">
    <!--Animation Banner-->
    <section class="overflow">
      <div class="container">
        <div class="main-image">
          <div class="boxText d-none d-md-flex">
            <v-row>
              <v-row v-if="currentText == 1 ">
                <v-col cols="12"> <h2 class="TitleChanging">Bienvenue chez WX3data !</h2> </v-col>
                <v-col cols="12"><p class="textChanging">Nous sommes ravis de vous accompagner avec une approche humaine, fiable et personnalisée. Ensemble, construisons des solutions clés en main pour faire prospérer vos projets.</p></v-col>
              </v-row>
              <v-row v-if="currentText == 2 ">
                <v-col cols="12"> <h2 class="TitleChanging">Authenticité humaine </h2> </v-col>
                <v-col cols="12"><p class="textChanging">Baser toutes les interactions sur l’honnêteté, la transparence et la bonté, tout en valorisant le bien-être et l’équilibre dans chaque relation humaine.</p></v-col>
                <v-col cols="12"> <h2 class="TitleChanging">Engagement et Passion  </h2> </v-col>
                <v-col cols="12"><p class="textChanging">Reconnaître et soutenir l’énergie et le dévouement que les entrepreneurs mettent dans leurs projets, en célébrant leur volonté de bâtir ce qui leur tient à cœur.</p></v-col>
              </v-row>
              <v-row v-if="currentText == 3 ">
                <v-col cols="12"> <h2 class="TitleChanging">Fiabilité</h2> </v-col>
                <v-col cols="12"><p class="textChanging">Offrir un accompagnement sûr, régulier et fiable pour permettre aux entrepreneurs de réussir.</p></v-col>
                <v-col cols="12"> <h2 class="TitleChanging">Sécurité et Éfficacité   </h2> </v-col>
                <v-col cols="12"><p class="textChanging">Proposer des solutions qui rendent la gestion de leur entreprise plus fluide, efficace et sécuritaire.</p></v-col>
              </v-row>
              <v-row v-if="currentText == 4 ">
                <v-col cols="12"> <h2 class="TitleChanging">Amélioration continue </h2> </v-col>
                <v-col cols="12"><p class="textChanging">Encourager la croissance personnelle et professionnelle, tout en favorisant le bien-être, l’équilibre et la recherche constante d’excellence.</p></v-col>
              </v-row>
            </v-row>
          </div>
          <div class=" firstPallelogram" @mouseover="setText(2)" @mouseleave="setText()">
            <div class="pallelogram ">
              <div id="BannerThree" class="">
                <h2 class="firstTitlePallelogram d-none d-lg-flex">Authenticité</h2>
                <div class="colorPallelogram"></div>
                <img class="imgPallelogram" src="/ressources/images/Home/help.jpg" alt="Une image qui représente l'Authenticité de WX3data" title="Une image qui représente l'Authenticité de WX3data" />
              </div>
            </div>
          </div>
          <div class="secondPallelogram" @mouseover="setText(3)" @mouseleave="setText()">
            <div class="pallelogram">
              <div id="BannerTwo" class="">
                <h2 class="secondTitlePallelogram d-none d-lg-flex">Sécuritaire</h2>
                <div class="colorPallelogram"></div>
                <img  class="imgPallelogram" src="/ressources/images/Home/secondImg.jpg" alt="Une image qui représente la Fiabilité de WX3data" title="Une image qui représente la Fiabilité de WX3data"  />
              </div>
            </div>
          </div>
          <div class="thirdPallelogram" @mouseover="setText(4)" @mouseleave="setText()">
            <div class="pallelogram">
              <div id="BannerOne" class="">
                <h2 class="thirdTitlePallelogram d-none d-lg-flex">Amélioration</h2>
                <div class="colorPallelogram"></div>
                <img  class="imgPallelogram" src="/ressources/images/Home/firstImg.jpg" alt="Une image qui représente l'amélioration continue de WX3data" title="Une image qui représente l'amélioration continue de WX3data"  />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!--text mobile-->
    <section class="overflow">
      <div class="boxText d-flex d-md-none">
            <v-row>
              <v-row v-if="currentText == 1 ">
                <v-col cols="12"> <h2 class="TitleChanging">Bienvenue chez WX3data !</h2> </v-col>
                <v-col cols="12"><p class="textChanging">Nous sommes ravis de vous accompagner avec une approche humaine, fiable et personnalisée. Ensemble, construisons des solutions clés en main pour faire prospérer vos projets.</p></v-col>
              </v-row>
              <v-row v-if="currentText == 2 ">
                <v-col cols="12"> <h2 class="TitleChanging">Authenticité humaine </h2> </v-col>
                <v-col cols="12"><p class="textChanging">Baser toutes les interactions sur l’honnêteté, la transparence et la bonté, tout en valorisant le bien-être et l’équilibre dans chaque relation humaine.</p></v-col>
                <v-col cols="12"> <h2 class="TitleChanging">Engagement et Passion  </h2> </v-col>
                <v-col cols="12"><p class="textChanging">Reconnaître et soutenir l’énergie et le dévouement que les entrepreneurs mettent dans leurs projets, en célébrant leur volonté de bâtir ce qui leur tient à cœur.</p></v-col>
              </v-row>
              <v-row v-if="currentText == 3 ">
                <v-col cols="12"> <h2 class="TitleChanging">Fiabilité</h2> </v-col>
                <v-col cols="12"><p class="textChanging">Offrir un accompagnement sûr, régulier et fiable pour permettre aux entrepreneurs de réussir.</p></v-col>
                <v-col cols="12"> <h2 class="TitleChanging">Sécurité et Éfficacité   </h2> </v-col>
                <v-col cols="12"><p class="textChanging">Proposer des solutions qui rendent la gestion de leur entreprise plus fluide, efficace et sécuritaire.</p></v-col>
              </v-row>
              <v-row v-if="currentText == 4 ">
                <v-col cols="12"> <h2 class="TitleChanging">Amélioration continue </h2> </v-col>
                <v-col cols="12"><p class="textChanging">Encourager la croissance personnelle et professionnelle, tout en favorisant le bien-être, l’équilibre et la recherche constante d’excellence.</p></v-col>
              </v-row>
            </v-row>
          </div>
    </section>
    <!--section anniversary-->
    <section class="overflow">
      <div id="anniversary"  class="grid-pad">
        <v-row style="height: 100%; position: relative; margin: 100px 0px;">
          <v-col cols="12" md="6" class="d-flex align-center justify-center containerImgAnniversary mt-5" style="">
            <img class="imgAnniversary" src="/ressources/images/Home/image25iem.png"  alt="photo des propriétaire de l'entreprise wx3data" title="photo des propriétaire de l'entreprise wx3data"/>
          </v-col>
          <v-col cols="12" md="6" class="textAnniversary"  style="height: 100%;">
            <div id="anniversaryAnimation" ref="observedAnniversary">
              <v-row >
                <v-col class="d-flex justify-center"><img class="logo25Anniversary " src="../../public/ressources/images/global/logo25years.svg" /></v-col>
              </v-row>
              <v-row >             
                <v-col class="textAnniversary">
                  <v-row><v-col><h2 class="scroll-btn-right" :class="{ 'is-visible': AnniversaryVisible  }">25 ans à vos côtés : une histoire de confiance et de&nbsp;réussite&nbsp;!</h2></v-col></v-row>
                  <v-row class="" style="position: relative;">
                    <div class="scroll-textes" :class="{ 'is-visible': AnniversaryVisible  }">
                      <v-col>
                        <p>Depuis un quart de siècle, nous avons le privilège de servir nos clients avec passion, authenticité et engagement. Ces 25 années témoignent de notre dévouement à offrir des solutions fiables, personnalisées et durables, tout en cultivant des relations humaines et authentiques.</p>
                        <p>Nous remercions sincèrement nos clients, partenaires et employés, qui font de cette aventure une véritable réussite. Ensemble, nous avons construit bien plus qu’une entreprise&nbsp;: une communauté basée sur la confiance, la collaboration et la passion.</p>
                        <p>Alors que nous célébrons nos 25 ans, nous nous engageons à continuer d’évoluer et à offrir le meilleur pour les années à venir. Merci de faire partie de notre histoire !</p>   
                      </v-col>
                    </div>
                  </v-row>   
                </v-col>
              </v-row>
              <v-row>
                <v-col class=" d-flex align-center justifer-center">
                  <router-link class="scroll-btn-right buttonHeader" :class="{ 'is-visible': AnniversaryVisible  }" style="margin: auto;" to="/#contact"><v-row style="position: relative;" ><v-col class=""><p class=" d-flex align-center justifer-center text">Rejoignez-nous</p></v-col></v-row></router-link>
                </v-col>
              </v-row>
            </div>
          </v-col>
        </v-row>
      </div>
    </section>
    <!--section Temoignage-->
    <section class="parallaxTwo parallax-2 overflow">
      <div ref="observedTestimony" id="testimony" style="height: 150%; background-color: rgba(var(--secondary),0.5);">
        <h2 class="TemoignageTitle scroll-textes" :class="{ 'is-visible': TestimonyVisible  }">Témoignages</h2>
        <!--<h3 class="txt-c parallaxTestimonyTexte">Un partenariat humain, fiable et clé en main, apprécié par nos clients depuis plus de 25 ans.</h3>-->
        <div>
          <v-carousel class="carousel" cycle :show-arrows="false" hide-delimiters  style=" position: relative;">
            <img class="logoSatisfation" src="../../public/ressources/images/global/iconTestimony.png" />
            <v-carousel-item>
              <v-row>
                <v-col  >
                  
                  <h3 class="parallaxTestimony" style="">Professionnalisme, Expérimenté, Conseil judicieux, Service Personnalisé !</h3>
                  <img class="logoTestimony" src="../../public/ressources/images/logo/logoSP360.png"  />
                </v-col>
              </v-row>
            </v-carousel-item>
            <v-carousel-item>
              <v-row>
                <v-col>
                  <h3 class="parallaxTestimony">
                    Ils ont pleinement notre confiance et nous les recommandons sans crainte.
                  </h3>
                  <img class="logoTestimony" style="" src="../../public/ressources/images/logo/logoFB.png"  />
                </v-col>
              </v-row>
            </v-carousel-item>
            <v-carousel-item>
              <v-row>
                <v-col>
                  <h3 class="parallaxTestimony">
                    Super service à la clientèle, ils sont disponibles et très à l'écoute de nos besoins. 
                  </h3>
                  <img class="logoTestimony" src="../../public/ressources/images/logo/logoMTQ.png"  />
                </v-col>
              </v-row>
            </v-carousel-item>
          </v-carousel>
        </div>
      </div>
    </section>
    <!--section realization-->
    <section class="overflow" >
      <div ref="observedRealization" id="realization"  class="">
        <h2 class="scroll-btn-left realisationTitle" :class="{ 'is-visible': RealizationVisible  }" >Réalisation</h2>
        <div class="containerRealization" style="">
          <v-slide-group v-model="model" style="height: 100%;" class="d-flex"  center-active   selected-class="selected">
          <template v-slot:prev="{ props }"> <h2 style="position: absolute; font-weight: 900; font-size: 5rem; color: rgb(var(--primary)); padding-right: 20px;" @click="tabindex='-1'"> &lt; </h2></template>
          <template  v-slot:next="{ props }"> <h2 style="position: absolute; font-weight: 900; font-size: 5rem; color: rgb(var(--primary)); padding-left: 20px;" @click="tabindex='-1'"> > </h2></template>  
          <v-slide-group-item v-for="item in realizations" class="mx-auto d-flex align-center" style=" transform: translate(-50%,-50%);  transform: translateY(-10%); transition: transform 2s ease;"   :key="item"  v-slot="{ isSelected, toggle, selectedClass  }">
              <v-card  class="neon-button  cardRealization" :class="['mx-4', selectedClass]" @click="toggle" >
                <div style="position: relative;" class="d-flex fill-height align-center justify-center">
                  <div class="scroll-btn-right" style="position: absolute; color: #FFF; opacity: 1; z-index: 100; text-align: center; " :class="{ 'is-visible': selectedClass  }">
                    <h2 v-if="selectedClass" style=" text-shadow: 3px 3px 2px rgb(var(--primary)); line-height: normal;" v-html="item.compagny"></h2>
                    <h2 v-if="selectedClass" style=" margin-top: 25px;"><a :href="item.pathWebsite" target="_blank" class="websiteLink">Visite le site</a></h2>
                  </div>
                  <div v-if="!selectedClass" class="colorGradient" ></div> <div v-else class="colorGradientSelected"></div>
                  <img style="position: absolute; bottom: 0; max-width: 80%; max-height: 100px;  padding-bottom: 10px" :src="item.logo" />
                  <img class="imgRealization" :src="item.Bg" />
                </div>
              </v-card>
            </v-slide-group-item>
          </v-slide-group>
        </div>
      </div>

    </section>
    <!--Parallax-->
    <section class="parallax parallax-1 overflow">
      <v-row class="" style="height: 105%; background-color: rgba(var(--primary),0.5);">
        <v-col class="d-flex align-center justify-center">
          <div id="parallax" ref="observedParallax">
            <h3  :class="{ 'is-visible': ParallaxVisible  }" class="scroll-textes parallaxTitle" >Pour un partenariat d’affaires humain, authentique, clé en main et durable, choisissez WX3data !</h3>
            
            <div class="borderParallax"></div>
            <v-row class="grid-pad">
              <h3 class="parallaxTitle newsletterTitle">Infolettre</h3>
              <p class="parallaxTexte" style="width: 100%;">Astuces, témoignages, nouveautés, événements et promotions</p>
               <v-col class="">
                  <div >
                    <form ref="newsLetter" id="newsLetter">
                      <v-row class="formFieldWidth" style="">
                        <v-col>
                          <v-text-field  @change="error.name = false" :class="{ 'error': ContactVisible }" placeholder="Nom"  id="Name" name="Name" type="text" v-model="item.name" clearable required />
                          <p class="bold error"  v-if="error.name"  style="font-size: 14px;">Le nom est obligatoire</p>
                        </v-col>  
                        <v-col>
                          <v-text-field @change="error.email = false" :class="{ 'errorBorder': ContactVisible }" placeholder="Courriel"  id="emailNewsletter" name="emailNewsletter" type="text" v-model="item.email" clearable required />
                          <p class="bold error"  v-if="error.email"  style="font-size: 14px;">Le courriel est obligatoire</p>
                        </v-col>                      
                      </v-row>
                      <v-row class="d-flex flex-lg-row flex-column">
                        <v-col cols="12" class="d-flex align-center justify-center">
                          <a v-if="this.Dialog != 'send'" @click="this.SendFormNews();" class="buttonWhite">
                            <v-row v-if="this.Dialog == 'notSend' || this.Dialog == 'error'" style="position: relative;" ><v-col class=""><p class=" d-flex align-center justifer-center text">Envoyé</p></v-col></v-row>
                            <v-row v-if="this.Dialog == 'pending'" style="position: relative;" ><v-col class=""><p class=" d-flex align-center justifer-center text">Veuillez patienter</p></v-col></v-row>
                          </a>
                          <h3 id="ContactFormSent" v-if="this.Dialog == 'send'" class="f-tierce" style=" font-size: 2em;">Merci de nous avoir contacté</h3>
                        </v-col>
                      </v-row>
                    </form>
                  </div>
               </v-col>
            </v-row>
          </div>
        </v-col>
      </v-row>   
    </section>
    <!--Service-->
    <section  class="sectionSpace overflow" >
      <div ref="observedServices" id="services">
        <h2 class="scroll-btn-left serviceTitle " :class="{ 'is-visible': ServicesVisible  }">Services</h2>
      </div>
      <div class="grid-pad" >
        <v-row style="height: 100%; position: relative; padding: 100px 0px">
          <v-row class="bgDealers">
            <v-col v-for="(item, i) in services" :key="i" cols="12" sm="6" md="4" lg="4" class="bgDealer">
              <div class="card glow dealerFront">
                <v-row>
                  <v-col cols="12">
                    <h2 class="titleCard">{{ item.title }}</h2>
                    <p class="textCardMobile">Clique sur moi!</p>
                    <img class="photoCard" :src="item.img"/>
                  </v-col>
                </v-row>
              </div>
              <div class="card  dealerBack">
                <v-row class="d-flex align-center ">
                  <v-col style="position: relative;" cols="12">
                    <v-row class="cardBack" style="">
                      <v-col class="d-flex align-center" cols="12">
                        <p class="titleService">{{ item.description }}</p>
                        <img style="position: absolute; bottom: 0%; right: 0%; z-index: 1; opacity: 0.2; height: 40%;" :src="item.icon" />
                      </v-col>
                    </v-row>                  
                  </v-col>
                </v-row>
              </div>
            </v-col>
          </v-row>
        </v-row>
      </div>
    </section>
    <!--Contact-->
    <section  class="overflow">
      <div class="gridPadMobiliter" id="contact" ref="observedContact">
        <h2 class="scroll-btn-right contactTitle" :class="{ 'is-visible': ContactVisible  }" style="">Contact</h2>
        <v-row stylegridPadMibiliter="overflow: hidden; ">
          
            <v-col style="padding: 0px 5%;">
              <div class="scroll-btn-left" :class="{ 'is-visible': ContactVisible }">
                <v-row class="" style="padding: 0% 0px 0% 0px; ">
                  <v-row style="width: 100%; ">
                    <v-col><h3 style="font-family: serif; font-weight: 700; font-size: 3rem; color: rgb(var(--secondary)); text-shadow: 2px 2px 3px rgb(var(--primary));">Coordonnées</h3></v-col>
                  </v-row>
                <v-row style="padding: 20px 5%;">
                  <v-row style="width: 100%; line-height:30%; ">
                    <v-col cols="12" lg="6" class="mobiliter">
                      <v-row><v-col cols="12" md="4"><h3 class="smallTitle ">Téléphone&nbsp;:</h3></v-col> <v-col><a class="phone" href="tel:8198187625">(819) 818-7625</a></v-col></v-row>
                      <v-row><v-col cols="12" md="4"><h3 class="smallTitle">Courriel&nbsp;:</h3></v-col><v-col><a class="courriel" href="mailto:info@wx3data.com">info@wx3data.com</a></v-col></v-row>
                      <v-row><v-col cols="12" md="4"><h3 class="smallTitle">Ouverture&nbsp;:</h3></v-col><v-col>Lundi au vendredi</v-col></v-row>
                      <v-row><v-col cols="12" md="4"></v-col><v-col>8h à 16h </v-col></v-row>
                    </v-col>
                    <v-col cols="12" lg="6" class="mobiliter">
                      <v-row> <v-col><h3 class="smallTitle">Adresse&nbsp;:</h3></v-col> </v-row>
                      <v-row> <v-col>275 Chem. Yamaska</v-col> </v-row>
                      <v-row> <v-col>Saint-Germain-de-Grantham</v-col> </v-row>
                      <v-row> <v-col>QC J0C 1K0</v-col> </v-row>
                    </v-col>
                  </v-row>
                  <v-row style="width: 100%;">
                    <v-row>
                      <!--Form-->
                      <v-col class="Form gridpadForm">
                          <div id="form" ref="observedForm">
                            <form ref="form" style="max-width: 90%; margin-left: 5%">
                              <v-row>
                                  <v-col>
                                    <!--name-->
                                    <v-row class="">
                                      <v-col class="fieldMin ">
                                        <v-row class="padForm infoTitle">
                                          <p class="bold f-primary scroll-textes-right"  v-if="!error.name" :class="{ 'is-visible': ContactVisible }"  style="font-size: 14px;">Nom</p>
                                          <p class="bold error"  v-if="error.name"  style="font-size: 14px;">Name est obligatoire</p><!--:class="$v.item.name.$invalid|validations"-->
                                        </v-row>
                                        <v-row class="padForm">
                                          <v-text-field @change="error.name = false" :class="{ 'errorBorder': ContactVisible }"  id="name" name="name" type="text" v-model="item.name" clearable required />
                                        </v-row>
                                      </v-col>
                                      <!--Adresse-->
                                      <v-col class="fieldMin">
                                        <v-row class="padForm infoTitle"><p class="bold f-primary scroll-textes-right" :class="{ 'is-visible': ContactVisible }" style="font-size: 14px;">Entreprise</p></v-row>
                                        <v-row class="padForm">
                                          <v-text-field id="enterprise" name="enterprise" type="text" v-model="item.enterprise" clearable required />
                                        </v-row>
                                      </v-col>
                                      <!--Courriel-->
                                      <v-col class="fieldMin">
                                        <v-row class="padForm infoTitle"><p class="bold f-primary scroll-textes-right" :class="{ 'is-visible': ContactVisible }" style="font-size: 14px;">Courriel</p></v-row>
                                        <v-row class="padForm">
                                          <v-text-field id="email" name="email" type="text" v-model="item.email" clearable required />
                                        </v-row>
                                      </v-col>
                                      <!--phone-->
                                      <v-col class="fieldMin">
                                        <v-row class="padForm">
                                          <p class="bold f-primary scroll-textes-right infoTitle" :class="{ 'is-visible': ContactVisible }" v-if="!error.phone" style="font-size: 14px;"> Téléphone </p>
                                          <p class="bold error" v-if="error.phone" style="font-size: 14px;" v-html="'Téléphone est obligatoire'"></p>
                                        </v-row>
                                        <v-row class="padForm">
                                          <v-text-field style="border: 50%;" id="Telephone" name="Telephone" type="text" label="xxx xxx-xxxx" v-model="item.phone" clearable required />
                                          
                                        </v-row>
                                      </v-col>
                                      <!--Work-->
                                      <v-col class="fieldFormMax">
                                        <v-row class="padForm infoTitle"><p class="bold f-primary scroll-textes-right" :class="{ 'is-visible': ContactVisible }" style="font-size: 14px;">Votre Projet</p></v-row>
                                        <v-row class="padForm">
                                          <v-textarea clearable required no-resize id="Message" name="Message" v-model="item.Message" label="Description du projet"  rows="7" row-height="45" single-line :counter="520" maxlength="520"/>
                                        </v-row>
                                      </v-col>
                                    </v-row>
                                  </v-col>
                              </v-row>

                              <!--accept form-->
                              <v-row class="d-flex flex-lg-row flex-column">
                                <v-col cols="12" class="d-flex align-center justify-md-center justify-start">
                                  <p v-if="this.Dialog == 'error'" class="" style="color: red; margin-left:15px;" v-html="'Un erreur est survenu, veuillez verifier vos information.'"></p>
                                </v-col>
                                <v-col cols="12" class="d-flex align-center justify-md-end justify-center">
                                  <a v-if="this.Dialog != 'send'" @click="this.SendForm();" class="button scroll-btn-right" :class="{ 'is-visible': ContactVisible }" style="margin-right:15px;">
                                    <v-row v-if="this.Dialog == 'notSend' || this.Dialog == 'error'" style="position: relative;" ><v-col class=""><p class=" d-flex align-center justifer-center text">Envoyé</p></v-col></v-row>
                                    <v-row v-if="this.Dialog == 'pending'" style="position: relative;" ><v-col class=""><p class=" d-flex align-center justifer-center text">Veuillez patienter</p></v-col></v-row>
                                  </a>
                                  <h3 id="ContactFormSent" v-if="this.Dialog == 'send'" class="f-primary" style=" font-size: 2em;">Merci de nous avoir contacté</h3>
                                </v-col>
                              </v-row>
                            </form>
                          </div>

                        </v-col>
                    </v-row>
                  </v-row>
                </v-row>
              </v-row>
              </div>
            </v-col>
          <v-col class="contactImgContainer" style="">
            <img class="photoContact" style="" src="ressources/images/Home/maison.jpg" alt="Bureau de wx3data" title="Bureau de wx3data"/>       
          </v-col>
        </v-row>
      </div>
    </section>
  </div>
</template>
<style>
    @media handheld, only screen and (max-width: 610px) {
        .v-carousel__controls {background: transparent; display: block; bottom: 10px; text-align: center;}
    }
    .v-carousel__controls {background: transparent;}
    .v-carousel__controls button {color: rgb(var(--primary));}
    .v-btn--selected {color: black !important;}
    .v-slide-group__content { min-height: 1000px; margin: 100px 0px; }
    .v-field { background-color: transparent; border: 1px solid rgb(var(--primary)); color: rgb(var(--primary)); }
    #newsLetter .v-field { background-color: transparent; border: 1px solid rgb(var(--tierce)); color: rgb(var(--tierce)); }
</style>
<style scoped>
    body { overflow: hidden; }
    .overflow { overflow: hidden; }
    .sectionSpace { margin: 100px 0px; }
    /*#region Banner*/
      .main-image { position: relative; margin-top:0px; box-shadow: inset 0px 0px 1000px black; min-height: 800px; background: url('../../public/ressources/images/Home/BG.jpg'); background-position: top center; background-repeat: no-repeat; background-size: cover;  position: relative; overflow: hidden; }
      .boxText { position: absolute; bottom: 10%; left: 0px; padding: 10px;  }
      .TitleChanging { padding: 0px 100px 0px 50px; font-size: 30px; color: white; max-width: 50%; text-transform: uppercase; color: rgb(var(--tierce)); text-shadow: 1px 1px 2px rgb(var(--primary));}
      .textChanging { padding: 0px 100px 0px 50px; font-size: 20px; color: white; max-width: 50%; text-shadow: 2px 2px 1px rgb(var(--secondary)); }
      .pallelogram { position: relative; height: 100%; overflow: hidden; }
      .firstPallelogram { position: absolute; right: -8%; width: 25%; height: 100%; clip-path: polygon(35% 0%, 100% 0%, 65% 100%, 0% 100%); transition: transform 1s ease;}
      .firstTitlePallelogram { position: absolute; z-index: 1000; top: 50%; left: 5%; transform: rotate(-79deg); font-size: 3rem; text-transform: uppercase; color: rgba(var(--tierce),0.7); }
      .pallelogram  img { transition: transform 10s ease; height: 150%; width: auto; position: absolute; z-index:1; top: 50%; left: 50%; transform: translate(-50%,-50%); }
      .secondPallelogram { position: absolute; right: 8%; width: 25%; height: 100%; clip-path: polygon(35% 0%, 100% 0%, 65% 100%, 0% 100%); transition: transform 0.3s ease, width 0.3s ease;}
      .secondTitlePallelogram { position: absolute; z-index: 1000; top: 50%; left: 10%; transform: rotate(-78deg); font-size: 3rem; text-transform: uppercase; color: rgba(var(--tierce),0.7); }
      .thirdPallelogram { position: absolute; right: 24%; width: 25%; height: 100%; clip-path: polygon(35% 0%, 100% 0%, 65% 100%, 0% 100%); transition: transform 0.3s ease, width 0.3s ease;}
      .thirdTitlePallelogram { position: absolute;z-index: 1000; top: 50%; left: 5%; transform: rotate(-78deg); font-size: 3rem; text-transform: uppercase; color: rgba(var(--tierce),0.7); }
      .colorPallelogram { position: absolute; z-index:10; height: 100%; width: 100%; background-color: rgba(var(--primary),0.6); }
      .pallelogram:hover img  { transform: translate(-50%, -50%) scale(1.5); z-index: 10;  }
      .pallelogram:hover .colorPallelogram { background-color: rgba(var(--secondary),0.5); z-index: 100; }
      .pallelogram:hover h2{ color: rgb(var(--tierce)); text-shadow: 1px 1px 2px rgb(var(--primary)); }

      .pallelogram .active img  { transform: translate(-50%, -50%) scale(1.5); z-index: 10;  }
      .pallelogram .active .colorPallelogram { background-color: rgba(var(--secondary),0.5); z-index: 100; }
      .pallelogram .active h2{ color: rgb(var(--tierce)); text-shadow: 1px 1px 2px rgb(var(--primary)); }

    /*#endregion */
    /*#region 25 anniversary*/
      .containerImgAnniversary { max-width: 50%; position: relative; margin-top: -2%; }
      .logo25Anniversary { max-width: 40%; max-width: 250px; }
      .imgAnniversary { max-height: 600px; }
      .imgAnniversary:hover { content: url('../../public/ressources/images/Home/image25iemHover.png'); }
      .textAnniversary p { font-size: clamp(0.75rem,2vw,1rem); text-align: justify; padding: 10px 20px;}
      .textAnniversary h2 {  }
    /*#endregion */
    /*#region temoignage*/
      .parallaxTwo { position: relative; height: 800px; background-attachment: fixed; background-position: center; background-repeat: no-repeat; background-size: cover; overflow: hidden; }
      .parallax-2 { background-image: url('../../public/ressources/images/Home/parallaxTestimony.jpg'); }
      .TemoignageTitle { position: relative; font-size: 10rem; max-height: 300px; z-index: 1000; color: rgb(var(--primary)); text-align: center; text-shadow: 3px 3px 5px rgb(var(--tierce)); }
      .parallaxTestimonyTexte { color: rgb(var(--tierce)); text-transform: uppercase; font-weight: 100; font-family: sans-serif; font-size: clamp(0.5rem, 3.5vw,1rem); text-align: center;}
      .parallaxTestimony { position: absolute; top: 35%; left: 50%; transform: translate(-50%,-50%); color: rgb(var(--tierce)); text-transform: uppercase; font-family: serif; width: 75%; font-size: clamp(0.5rem, 3.5vw,1.5rem); text-align: center;text-shadow: 1px 1px 3px black; }
      .logoTestimony { position: absolute; top: 55%; left: 50%; transform: translate(-50%,-50%); max-width: 200px; margin: 10px auto; }
      .logoSatisfation { position: absolute; width: 300px; bottom: 0; right: 10%; transform: rotate(25deg);}
      /*#endregion */
    /*#region realization*/
      .selected { transform: translateY(-10%);  animation: animate 2.5s ease-in-out; }
      .realisationTitle { position: relative;  font-size: 15rem; max-height: 300px; z-index: 1000; color: rgb(var(--primary)); }
      .containerRealization { margin:0px auto 0px auto; height: 1000px; width: 90%; position: relative; }
      .imgRealization { position: absolute; left: -55%; height: 160%; width: auto; z-index: -1; }
      .cardRealization { height:600px; width:450px; }
      .colorGradient {  height: 100%; width: 100%; position: absolute; background-image: linear-gradient(to bottom, rgba(var(--primary),0), rgba(var(--secondary),0.8)); }
      .colorGradientSelected {  height: 100%; width: 100%; position: absolute; background-image: linear-gradient(to bottom, rgba(var(--secondary),0), rgba(var(--primary),0.8)); }
      .websiteLink { color: rgb(var(--tierce)); text-shadow: 3px 3px 2px rgb(var(--primary)); }
      .websiteLink:hover { color: rgb(var(--primary)); text-shadow: 3px 3px 2px rgb(var(--tierce)); }
      .neon-button.selected::before {
        animation: scalingShadow 0.5s ease-in-out;
      }

        @keyframes animate {
        0% {
          transform: translateY(0%);
        }
        5% {
          transform: translateY(1%);
        }
        70% {
          transform: translateY(-10%);
        }
      }
    /*#endregion */
    /*#region Parralax*/
      .parallax { position: relative; height: 1000px; background-attachment: fixed; background-position: center; background-repeat: no-repeat; background-size: cover; overflow: hidden; }
      .parallax-1 { background-image: url('../../public/ressources/images/Home/parallax.jpg'); }
      .parallaxTitle { color: rgb(var(--tierce)); text-transform: uppercase; font-family: serif; font-size: clamp(1rem, 3.5vw,2.5rem); text-align: center; margin: 0px 5%; text-shadow: 1px 1px 3px black; }
      .parallaxTexte { color: rgb(var(--tierce)); text-transform: uppercase; font-family: serif; font-size: clamp(0.5rem, 3.5vw,1rem); text-align: center; margin: 0px 5%; text-shadow: 1px 1px 3px black; }
      .borderParallax { border-bottom: 1px solid rgb(var(--tierce)); max-width: 33%; margin: 50px auto 0px auto; }
      .newsletterTitle { width: 100%; margin-top: 50px }
      .formFieldWidth { max-width: 50%; margin: auto; }
      /*#endregion */
    /*#region services*/
      .bgDealer { position: relative; min-width: 300px; max-width: 350px;  width: 33%; height: 400px; margin: 20px auto; perspective: 1000px;  }
      .card { width: 100%; height: 100%; position: absolute; transform: translateY(30px);  backface-visibility: hidden; transition: transform 1.5s; overflow: hidden;  border-image: linear-gradient(to right, rgb(var(--primary)) 0%, rgb(var(--secondary)) 100%) 1; border-radius: 5px; border-width: 4px; border-style: solid; padding: 5px; }
      .photoCard { height: 150%; width: auto; position: absolute; top:50%; left:50%; transform: translate(-50%,-50%); z-index: 1; }
      .titleCard { position: absolute; width: 100%; top:50%; left:50%; transform: translate(-50%,-50%); z-index: 10; font-size: clamp(1rem,2vw,1.25rem); font-family: sans-serif; text-align: center; color: rgb(var(--tierce)); text-shadow: 2px 2px 2px rgb(var(--primary)); font-weight: 900; text-transform: uppercase; }
      .titleService { font-size: clamp(1.5rem,2.8vw,1.5rem); font-family: sans-serif; width: 90%; margin: 10px auto; text-align: center; font-weight: 100;   }
      .dealerBack { transform: rotateY(180deg); box-shadow: 0 0 50px 0px rgb(var(--primary)); }
      .cardBack { height: 400px; }
      .bgDealer:hover .card { transform: translateY(30px);  }
      .bgDealer:hover .dealerBack { transform: rotateY(0deg); z-index: 1000; }
      .bgDealer:hover .dealerFront { transform: rotateY(180deg); }
      .textCardMobile { display: none; }
      .serviceTitle { position: relative; font-size: 15rem; z-index: 100; color: rgb(var(--primary)); }
    /*#endregion */

    /*#region contact*/
        .contactTitle { position: relative; font-size: 15rem; max-height: 200px; z-index: 100; color: rgb(var(--primary)); text-align: right; }
        .smallTitle { font: 700; font-size: 1rem; font-family: sans-serif; }
        /*#region Formulaire*/
          .expertText { font-family: Comorant; text-align: left; color: rgb(var(--secondary)); margin: 20px 0px; }
          .Form { margin: 50px 0px; }
          .fieldMin { min-width: 50%; }
          .fieldFormMax { min-width: 100%; }
          .padForm { padding: 0px 10px !important;}
        /*#endregion */
        .contactImgContainer { min-width: 50%; height: inherit; overflow: hidden; position: relative; box-shadow:  5px 20px 30px rgb(var(--secondary)); }
        .photoContact { position: absolute; height: 120%; width: auto; top:50%; left: 50%; transform: translate(-60%,-50%); }
    /*#endregion */
    @media handheld, only screen and (max-width: 600px) {
      


    }
    @media handheld, only screen and (max-width: 960px) {
      .textAnniversary { min-width: 100%; }

      .cardRealization { height:400px; width:450px;padding: 0px; }
      .containerRealization {  height: 600px;}
      .imgRealization {  left: -25%;}
      .bgDealer { height: 250px;   }
      .card { width: 80%;  margin: auto;}

      .sectionSpace {margin: 10px 0px;}
   
      .cardBack { height: 300px; }
      .titleService { font-size: clamp(1rem,2vw,1rem);   }
        /*#region Banner*/
        .main-image { min-height: 400px;}
        .boxText { position: relative; bottom: 0%; left: 0px; padding: 10px;  margin: 50px 0px; }
        .TitleChanging { padding: 0px 0px 0px 10px; font-size: 18px; color: rgb(var(--primary)); max-width: 100%; text-transform: uppercase; text-shadow: unset; }
        .textChanging { padding: 0px 0px 0px 10px; font-size: 12px; color: black; max-width: 100%; text-shadow: unset; }
        .firstPallelogram {  right: -12%; width: 35%; }
        .secondPallelogram { right: 8%; width: 35%; }
        .thirdPallelogram { right: 30%; width: 35%; }
        /*#endregion */
      .TemoignageTitle { font-size: 3rem;  }
      .logoSatisfation { width: 150px; right: 0%; }
      .logoTestimony { max-width: 150px; margin: 30px auto; max-height: 50px; }
      .parallaxTwo { height: 400px; }	
      .carousel { height: 300px !important; }
      .parallaxTestimony { width: 100%;}	
      .realisationTitle { font-size: 3rem;  }
      .serviceTitle { font-size: 3rem;  }
      .contactTitle { font-size: 3rem; margin-top: 100px; }

      .imgAnniversary { max-width: 100%; }
      .containerImgAnniversary { max-width: 100%; position: relative; margin-top: -2%; }
      .textCardMobile { position: absolute; bottom: 0; left: 50%; transform: translate(-50%,0%); display: block; }


      .parallax { height: 400px; }
      .borderParallax { margin: 10px auto 10px auto; }
      .newsletterTitle { margin: 10px auto 0px auto; }
      .formFieldWidth { max-width: 100%; margin: auto; }


      .mobiliter { min-width: 100%; }
      .gridPadMobiliter { padding: 0px 10px; }
      .fieldMin { min-width: 100%;  }
      .Form { margin: 0px 0px; min-width: 100% !important; }
      .infoTitle { width: 100%;}
      .photoContact { height: 200%; top: 80%;}
      .contactImgContainer { margin-top: 50px; width: 100%; box-shadow:  unset; overflow: hidden; height: 400px; min-width: 100% !important; }
    }
    @media handheld, only screen and (max-width: 1279px) {
      .textAnniversary { min-width: 100%; }
    }
    @media handheld, only screen and (max-width: 1378px) {
    }
    @media only screen and (max-width: 1700px) {
      .firstTitlePallelogram { left: -5%; }
      .secondTitlePallelogram { left: 5%; }
      .thirdTitlePallelogram  { left: 0%; }
    }
</style>
<script>
//import translations from '../../public/Resources/i18N/global.json';
export default {
    name: 'Home',
    data: () => ({
      currentText: 1,
      ids: ['BannerOne', 'BannerTwo', 'BannerThree'],
      bannerTimerActive: true,
      activeBannerIndex: 0,
      services: [
        {
          title: 'Service web en continu',
          description:"Un soutien annuel clé en main pour le web et l'informatique, assurant la tranquillité d'esprit de nos clients.",
          alt: '',
          titleSeo:'',
          categorie: [1,2,3],
          img: '/ressources/images/Home/service1.jpg',
          icon:'/ressources/images/global/shake-hand-contact.svg'
        },
        {
          title: 'site internet et commerce en ligne',
          description:"Un service sur mesure qui assure une forte visibilité en ligne, adapté aux besoins de votre entreprise",
          alt: '',
          titleSeo:'',
          categorie: [1,2],
          img: '/ressources/images/Home/service2.jpg',
          icon:'/ressources/images/global/media.svg'
        },
        {
          title: 'Programmation personnalisée',
          description:"Un service de développement de logiciels sur mesure pour répondre à tous les besoins de votre entreprise.",
          alt: '',
          titleSeo:'',
          categorie: [1,2],
          img: '/ressources/images/Home/secondImg.jpg',
          icon:'/ressources/images/global/web-application.svg'
        },
        {
          title: "Service d'aide au démarrage d'entreprise",
          description:"Une offre à prix abordable pour accompagner les nouvelles entreprises (création de logo, cartes d'affaires, CRM, site web).",
          alt: '',
          titleSeo:'',
          date: '18 juin 2015',
          categorie: [1,2],
          img: '/ressources/images/Home/help.jpg',
          icon:'/ressources/images/global/board-analysis.svg'
        }
      ],
      model: 1,
      realizations:[
        {
          Bg:'/ressources/images/realisations/FB.jpg',
          logo:'/ressources/images/logo/logoFB.png',
          compagny:'Fleuriste Bergeron',
          pathWebsite:'https://www.fleuristebergeron.com/'
        },
        {
          Bg:'/ressources/images/realisations/CMI.jpg',
          logo:'/ressources/images/logo/logoCMI.png',
          compagny:'CMI Mulching',
          pathWebsite:'https://www.cmimulching.com/fr'
        },
        {
          Bg:'/ressources/images/realisations/STG.jpg',
          logo:'/ressources/images/logo/logoSTG.png',
          compagny:'Municipalité de Saint-Germain-de-Grantham',
          pathWebsite:'https://st-germain.info/'
        },
        {
          Bg:'/ressources/images/realisations/MTQ.jpg',
          logo:'/ressources/images/logo/logoMTQ.png',
          compagny:'Marquage Traçage Québec',
          pathWebsite:'https://mtq.wx3data.com/'
        },
        {
          Bg:'/ressources/images/realisations/PMR.jpg',
          logo:'/ressources/images/logo/logoPMF.png',
          compagny:'Potager Mont-Rouge Halte Gourmande',
          pathWebsite:'https://pmrhaltegourmande.ca/'
        }
        
      ],
      selected: {src:'Resource/Diapo/Wedding/centreTable-Large.jpg',},
      columns: 3,
      item: {
          name: null,
          email: '',
          phone: null,
          enterprise: null,
          Message: null
        },
        Dialog: "notSend", //"error, send,notSend"
        error:{
          name: false,
          phone: false,
          email: false
        },
        AnniversaryVisible: false,
        TestimonyVisible: false,
        RealizationVisible: false,
        ParallaxVisible: false,
        ServicesVisible: false,
        ContactVisible: false,
    }),
    async mounted() {
        //this.$i18n.mergeLocaleMessage('fr', translations.fr);
        //this.$i18n.mergeLocaleMessage('en', translations.en);
        this.changeColums();
        await this.createObserver();
        setInterval(this.switchActiveClass, 10000);
    },
    methods: {
      setText(text = 1) { if(text != 1){ this.currentText = text; this.bannerTimerActive = false; this.switchActiveClass();} else { this.currentText = 1; } },
      changePhoto: function(src) {
        document.getElementById("principalImage").lastElementChild.src = src;
      },
      changeColums: function(){
          var w = window.document.documentElement.clientWidth;
          console.log(w);
          if (w < 700) {this.columns = 1;} 
          else if (w < 960) {this.columns = 2;}
          else{this.columns= 3;}
      },
      validation: async function(){
        let valid = true;
        let errorFlag = {name: false, phone: false, email: false};
        if(this.item.name == null || this.item.name.length == 0){ valid = false; errorFlag.name = true; }
        if(this.item.phone == null || this.item.email.length < 12){valid = false; errorFlag.phone = true; }
        this.error = errorFlag;
        return valid;
      },
      validationNewsForm: async function(){
        let valid = true;
        let errorFlag = {name: false, phone: false, email: false};
        if(this.item.name == null || this.item.name.length == 0){ valid = false; errorFlag.name = true; }
        if(!this.item.email != '' || !this.item.email.includes('@')){valid = false; errorFlag.email = true; }
        this.error = errorFlag;
        return valid;
      },
      login: async function () {

        let api = 'https://abstracxion.wx3data.com/security/login/WX3'; // need Tenant BissonService 
        let data = null;
        try {
            let response = await fetch(api, {
                method: 'PUT', // *GET, POST, PUT, DELETE, etc.
                mode: 'cors', // no-cors, *cors, same-origin
                cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
                credentials: 'same-origin', // include, *same-origin, omit
                headers: {
                    'Content-Type': 'application/json'
                },
                redirect: 'follow', // manual, *follow, error
                referrerPolicy: 'no-referrer', // no-referrer, *client
                body: JSON.stringify({LoginName: "", password: ""}) // body data type must match "Content-Type" header
            })
            if (await response.ok) {
                if (await response.status !== 204) {
                    let data = await response.text();
                    return data
                }
            } else {
                let msg = await response.text();
                $App.event.$emit("app_error", msg);
            };
                
        } catch (error) {
            console.log(error);
        }
      },
      SendForm: async function (){
        this.Dialog = "pending";
        let valid = await this.validation();
        if(valid == true){
          let formData = new FormData(this.$refs.form);
          let api = 'https://abstracxion.wx3data.com/eCommerce/Forms/ContactUs/sendByEmail';
          try {
              let token = await this.login();

              // ** ATTENTION need to add bearer token...
              let response = await fetch(api, {
                  method: 'POST',
                  mode: 'cors', // no-cors, *cors, same-origin
                  cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
                  credentials: 'same-origin', // include, *same-origin, omit
                  headers: {
                      'Authorization': 'Bearer ' + token
                  },
                  redirect: 'follow', // manual, *follow, error
                  referrerPolicy: 'no-referrer', // no-referrer, *client
                  body: formData
              });

              if (await response.ok) {
                  if (await response.status !== 204) {
                    this.Dialog = "send";
                  }
              } else {
                this.Dialog = "error";
              };

          } catch (error) {

          }
        }else { this.Dialog = 'error'; }

      },
      SendFormNews: async function (){
        console.log('send form news');
        this.Dialog = "pending";
        let valid = await this.validationNewsForm();
        if(valid == true){
          let formData = new FormData(this.$refs.newsLetter);
          let api = 'https://abstracxion.wx3data.com/eCommerce/Forms/ContactUs/sendByEmail';
          try {
              let token = await this.login();

              // ** ATTENTION need to add bearer token...
              let response = await fetch(api, {
                  method: 'POST',
                  mode: 'cors', // no-cors, *cors, same-origin
                  cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
                  credentials: 'same-origin', // include, *same-origin, omit
                  headers: {
                      'Authorization': 'Bearer ' + token
                  },
                  redirect: 'follow', // manual, *follow, error
                  referrerPolicy: 'no-referrer', // no-referrer, *client
                  body: formData
              });

              if (await response.ok) {
                  if (await response.status !== 204) {
                    this.Dialog = "send";
                  }
              } else {
                this.Dialog = "error";
              };

          } catch (error) {

          }
        }else { this.Dialog = 'error'; }

      },
      async createObserver() {
        // Options pour l'IntersectionObserver
        const options = {
          threshold: 0.3, // La section est visible lorsqu'elle est à 50% dans la vue
        };
        
        // Créer un nouvel observateur Service
        const observer = new IntersectionObserver(this.handleIntersectionServices, options);
        const targetAnniversary = this.$refs.observedAnniversary;
        const targetTestimony   = this.$refs.observedTestimony;
        const targetRealization   = this.$refs.observedRealization;
        const targetParallax = this.$refs.observedParallax;
        const targetServices = this.$refs.observedServices;
        const targetContact = this.$refs.observedContact; 

        if (targetAnniversary) { observer.observe(targetAnniversary);}
        if (targetTestimony) { observer.observe(targetTestimony);}
        if (targetRealization) { observer.observe(targetRealization);}
        if (targetParallax) { observer.observe(targetParallax);}
        if (targetServices) { observer.observe(targetServices);}
        if (targetContact) { observer.observe(targetContact);}
      },
      handleIntersectionServices(entries) {
        entries.forEach((entry) => {
          let e = entry.target.id;
          let w = window.document.documentElement.clientWidth;
          
          switch (e) {
            case 'anniversaryAnimation':
              if (entry.isIntersecting) { this.AnniversaryVisible = true; } 
              else { this.AnniversaryVisible = false; }
              break;
            case 'testimony':
              if (entry.isIntersecting) { this.TestimonyVisible = true; } 
              else { this.TestimonyVisible = false; }
              break;
            case 'realization':
              if (entry.isIntersecting) { this.RealizationVisible = true; } 
              else { if( w < 960 ) { this.RealizationVisible = true; } else { this.RealizationVisible = false;} }
              break;
            case 'parallax':
              if (entry.isIntersecting) { this.ParallaxVisible = true; } 
              else { this.ParallaxVisible = false; }
              break;
            case 'services':
              if (entry.isIntersecting) { this.ServicesVisible = true; } 
              else { this.ServicesVisible = false; }
              break; 
            case 'contact':
              if (entry.isIntersecting) { this.ContactVisible = true; } 
              else { if( w < 960 ) { this.ContactVisible = true; } else{ this.ContactVisible = false; }  }
              break; 

            default:
              break;


          }
        });
      },
      switchActiveClass() {
        if( this.bannerTimerActive ){ 
                  // Supprimer la classe "active" de tous les éléments
              this.ids.forEach(id => {
                  const element = document.getElementById(id);
                  if (element) {
                      element.classList.remove('active');
                  }
              });

              // Ajouter la classe "active" à l'élément courant
              const currentElement = document.getElementById(this.ids[this.activeBannerIndex]);
              if (currentElement) {
                  currentElement.classList.add('active');
              }

              // Passer à l'élément suivant
              this.activeBannerIndex = (this.activeBannerIndex + 1) % this.ids.length; // Revenir au début après le dernier élément
        } else { 
          this.ids.forEach(id => {
              const element = document.getElementById(id);
              if (element) {
                  element.classList.remove('active');
              }
          });
        }
      }

    },
    component: {}
}
</script>
